import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import MarkdownRenderer from "../components/MarkdownRenderer";

export default () => {
    const { language } = useI18next();
    const markDownFileName = language === "en" ? "cgv_en.md" : "cgv.md";
    
    return (
        <div className="markdown">
            <MarkdownRenderer markdownFileName={markDownFileName} />
        </div>
    );
}